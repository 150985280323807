import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { Suspense } from 'react'
import './global.scss'

import { lazyWithRetry } from './utils/lazy-with-retry'
import { InitialLoader } from './ui/InitialLoader/InitialLoader'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

const App = lazyWithRetry(() => import('./app/App'), 'app')
const GenericError = lazyWithRetry(() => import('./routing/GenericError/GenericErrorWrapper'), 'generic-error')

function ErrorFallback() {
  return (
    <Suspense fallback={<InitialLoader />}>
      <GenericError />
    </Suspense>
  )
}

root.render(
  <Sentry.ErrorBoundary fallback={ErrorFallback}>
    <Router>
      <Suspense fallback={<InitialLoader />}>
        <App />
      </Suspense>
    </Router>
    <SpeedInsights />
  </Sentry.ErrorBoundary>
)
